.customer__header {
  border-top: 2px solid $color-gray-dark;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.customer__back-link {
  background: $color-gray-dark;
  font-size: 2rem;
  height: 100%;
  margin-right: .5rem;
  padding: 1rem 1.5rem;
}

.customer__header .customer-avatar {
  margin: 0 1rem;
}