#prezzi {
  .col-lg-3 {
    margin-bottom: 5rem;

    @include media-query('l') {
      margin-bottom: 0;
    }
  }
}

.plan {
  height: 90%;
}

.plan__header {
  background: $color-intraweb;
  border-radius: .5rem;
  color: white;
  padding: .5rem;
}

.plan__name,
.plan__price {
  display: block;
  text-align: center;
  text-transform: uppercase;
}

.plan__name { font-size: 2rem; }

.plan__price { font-size: 1.5rem }

.plan__body {
  background: #f6f6f6;
  margin: 0 .5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
}

.plan__list {
  list-style: none;
  text-align: center;
  padding-left: 0;
}

.plan__list-item {
  padding: 1rem;
  color: #706f6f;
  border-bottom: 1px solid #706f6f;
}