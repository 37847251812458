/*------------------------------------*\
 #BASE
\*------------------------------------*/

html {
  min-height: 100%;
  position: relative;
}

body {
  color: $color-gray-dark;
  font-family: 'Lato', sans-serif;
  overflow-x: hidden;
  position: relative;
}