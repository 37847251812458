.btn--accent {
  background: $color-accent;
  border-color: $color-accent;
  border-radius: 0;
  color: #fff;
  transition: background .15s ease-out;

  &:hover,
  &:active {
    border-color: darken($color-accent, 10) !important;
    background: darken($color-accent, 10) !important;
    color: #fff;
  }
}

.btn--intraweb {
  background: $color-intraweb;
  border-color: $color-intraweb;
  color: #fff;
  transition: background .15s ease-out;

  &:hover,
  &:active {
    border-color: darken($color-intraweb, 10) !important;
    background: darken($color-intraweb, 10) !important;
    color: #fff;
  }
}