.note-modal {
  color: #706f6f;

  .modal-body {
    padding: 0 1rem 1rem;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-content {
    background: $color-gray-light;
  }

  .modal__form {
    border-top: 1px solid $color-gray-dark;
    padding-top: 1rem;
  }

  .form-control,
  .form-control:focus {
    background: #fff;
  }
}