.customer-list {
  .list-group-item-action {
    border-width: 1px 0 0 0;
  }
}

.customer-link {
  display: flex;
  align-items: center;
  flex: 1;

  &:hover {
    text-decoration: none;
  }
}

.customer-avatar {
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  flex-shrink: 0;
  height: 4rem;
  width: 4rem;
  margin-right: .5rem;
}

.clients-list__img {
  max-width: 4rem;
  max-height: 4rem;
  margin-right: 1rem;
}

.clients-list__search {
  width: 100%;
}

.clients-list__search-results {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 5;
}