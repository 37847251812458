.main-nav {
  background: white !important;
  border-bottom: 1px solid $color-intraweb;
  list-style: none;
  padding-bottom: .5rem;

  @include media-query('l') {
    text-align: center;
    padding-bottom: 0;
  }

  .navbar-nav {
    border-bottom: 0;
    width: 100%;

    @include media-query('l') {
      display: block;
      text-align: center;
    }
  }
}

.main-nav__item {
  display: inline-block;
  color: $color-intraweb !important;
  text-transform: uppercase;

  &:hover {
    color: $color-intraweb !important;
  }

  @include media-query('l') {
    &:not(:last-child)::after {
      content: '|';
      color: $color-intraweb !important;
      display: inline-block;
      margin-left: 1rem;
    }
  }
}