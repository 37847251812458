#particles-js {
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.container--hero {
  background: url("../img/background-landing.jpg") center center no-repeat;
  background-size: cover;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: .3;
}

.slogan h2 { color: white; }

.slogan__img {
  display: block;
  margin: 0 auto;
}

.slogan__hr {
  @include media-query('l') {
    width: 60%;
  }
}

.ul--lead {
  list-style: none;
  margin-bottom: .5rem;

  li {
    display: flex;
    font-size: 1.2rem;
    align-items: flex-start;
    align-content: center;
    margin-bottom: 1rem;

    &::before {
      content: url('../img/icons/arrow.png');
      display: inline-block;
      margin-right: .5rem;
      margin-top: .25rem;
    }
  }
}

.col--lead-1,
.col--lead-2 {
  background-size: auto;
  text-align: right;

  img {
    display: inline-block;
    margin-right: 1rem;
  }
}

.col--lead-1 {
  background: url('../img/background-cosa-puo-fare.png') center right no-repeat;

  img {
    max-width: 80%;
  }
}

.col--lead-2 {
  background: url('../img/background-strumenti-evoluti.png') center center no-repeat;

  img {
    max-width: 60%;
  }
}

.slide-in {
  opacity: 0;
  transition: all .6s;
}

.slide-in.slide-in--active {
  opacity: 1;
  transform: translateX(0);
}

.slide-in--left {
  transform: translateX(-30%);
}

.slide-in--right {
  transform: translateX(30%);
}