/*------------------------------------*\
 #TRUMPS
\*------------------------------------*/

.container--landing { overflow-x: hidden; }

.bg--accent { background: $color-accent !important; }

.container--accent {
  background: $color-accent !important;
  color: #fff;
}

.container--50 {
  width: 100%;
  margin: 0 auto;

  @include media-query('l') {
    width: 50%;
  }
}

.container--full { height: 100vh; }

.text--accent { color: $color-accent; }

.img--navbar { max-width: 13rem; }

.container--border-b { border-bottom: 2px solid #555; }

.dropdown-item--dark:focus,
.dropdown-item--dark:hover {
  background-color: #706f6f;
  color: #fff;
}

.form-control,
.form-control:focus {
  background-color: $color-white;
  border-radius: 0;
}

.hr--bold { border-top: 1px solid black; }

.hr--bold-white { border-top: 1px solid white; }

.font-weight-thin { font-weight: 100; }

.text--intraweb { color: $color-intraweb; }

.text--underlined-intraweb {
  &::after {
    content: '';
    display: block;
    background: $color-intraweb;
    width: 100%;
    height: .25rem;
    margin-top: .25rem;
  }
}

.text--gray-dark { color: $color-gray-dark; }

.text-justify { text-align: justify; }