.installation {
  display: none;
  background: rgba(0,0,0, .6);
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 100;
  cursor: pointer;
}

.icons--share-2 { margin-left: -5rem; }