.note {
  background: $color-gray;
  border-radius: .5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 3rem;
  padding: 1rem;
  position: relative;

  &::after {
    content: '';
    background: $color-gray;
    display: block;
    height: 2rem;
    width: 2rem;
    position: absolute;
    bottom: -.5rem;
    left: 1rem;
    transform: rotate(65deg);
    z-index: -1;
  }
}

.note__date {
  position: absolute;
  top: 100%;
  right: 0;
}